<template id="task_index_item_box-template">
    <div class="card mb-3 kanban-item" :class="variant_class">


        <div class="p-3" @click.prevent="clickEdit">
            <div class=" mb-2">
                <div class="item-badges">
                    <div class="badge rounded-pill bg-label-info"> {{ dateF(item.task_end_at) }}</div>

                </div>
            </div>
            <div>

                <a href="#" class="text-secondary">{{ item.task_name }}</a>
            </div>

            <div class="d-flex justify-content-between align-items-center flex-wrap mt-2 pt-1">
                <div class="d-flex"> 
                    <MagicoAvatar :size="24" :user_id="item.user_id"></MagicoAvatar>
                </div>
                <div class="avatar-group d-flex align-items-center assigned-avatar">

                    <!-- <div class="avatar avatar-xs" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Iris"
                        data-bs-original-title="Iris"><img
                            :src="getUser(item.user_id).image ? '/' + getUser(item.user_id).image : null" alt="-"
                            class="rounded-circle  pull-up"></div> -->
                            <task_priority :value="item.task_priority"></task_priority>

                </div>
            </div>


            <!-- <div class="task-list-table w-100 p-2">
                <span v-if="item.notifications.length > 0" v-b-tooltip="generateTooltip(item, box_variant)"
                    class="float-right  btn material-icons-outlined" :class="'text-' + box_variant"><span
                        class="material-icons-outlined">notifications</span></span>
            </div> -->
        </div>
    </div>
</template>
<script>
import dayjs from "dayjs";
import MagicoAvatar from "../../../components/MagicoAvatar.vue";
import TaskPriority from "./TaskPriority.vue";

export default {
    name: 'TaskIndexItemBox',
    components: {
    "task_priority": TaskPriority,
    MagicoAvatar
},
    props: ['variant', 'post', 'editurl'],
    data: function () {
        return {
            slownik_user: this.$store.state.slownik_user,
            box_variant: 'primary',
            variant_class: '',
            box_url: '',
        }
    },
    computed: {},
    methods: {
        dateF: function (date) {
            if (dayjs().format('YYYY') == dayjs(date).format('YYYY')) {
                return dayjs(date).format('D MMM');
            }
            return dayjs(date).format('D MMM YYYY');
        },
        change() {
            //zmienił sie modal, wysyłamy zeby przeladować liste wyzej.
            this.$emit('change');
        },
        getUser(id) {
            var name = { name: 'nie przypisano' };
            this.slownik_user.forEach(function (item) {
                if (item.user_id == id) {
                    name = item;
                }
            })
            return name;
        },
        generateTooltip: function (item, variant) {
            var text = '';
            item.notifications.forEach(i => {
                if (i.task_notification_status == 'done') {
                    text = '<s>' + i.task_notification_end_at + '</s>' + '<br />' + text;
                } else {
                    text = '' + i.task_notification_end_at + '' + '<br />' + text;
                }
            })
            var a = {
                title: text,
                placement: 'bottom',
                variant: variant,
                'html': true
            }
            return a;
        },
        clickEdit: function () {
            this.$router.push(this.box_url);
        },
    },
    created() {
        var variants = {
            primary: 'blue',
            warning: 'yellow',
            success: 'green',
            check: 'purple',
        }
        this.box_variant = this.variant;
        this.box_edit = this.edit;
        this.variant_class = 'card-border-c-' + variants[this.box_variant]; //green
        this.item = this.post
        this.box_url = (this.editurl) ? this.editurl : '/show/' + this.item.task_id;
    }
}

</script>
<style>
.kanban-item:hover {
    box-shadow: 0 0.25rem 1rem rgba(161, 172, 184, 0.45) !important;
    cursor:move
}
</style>