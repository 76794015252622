<template>
    <img v-if="value=='low'" width="20" height="20" src="@/assets/icons/down-arrow.svg" :title="title">
    <img v-else-if="value=='hight'" width="20" height="20" src="@/assets/icons/up-arrows.svg" :title="title">
    <img v-else width="20" height="20" src="@/assets/icons/equal.svg" :title="title">
</template>
<script>
export default {
    name: 'task_priority',
    template: '<img width="20" height="20" :src="src" :title="title">',
    components: {},
    props: ['value'],
    data: function () {
        return {
            src: '/assets/images/',
            title: '',
        }
    },
    computed: {},
    methods: {},
    created() {
        // var item = ['highg','low','normal'];
        this.title = 'Normalny';
        this.src = '@/assets/icons/equal.svg';
        if (this.value == 'low') {
            this.title = 'Niski';
            this.src = '../assets/icons/down-arrow.svg';
        }
        if (this.value == 'hight') {
            this.title = 'Wysoki';
            this.src = '../assets/icons/up-arrows.svg';
        }
    }
}
</script>