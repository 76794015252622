<template id="task-template">
    <div class="container-xxl flex-grow-1 container-p-y app-kanban">

        <div class="row kanban-wrapper">
            <div class="col-12 col-md-12 mb-4">
                <div class="card kanban-wrapper">
                    <div class="card-header d-flex justify-content-between flex-wrap mb-0 pb-1 ">
                        <div>
                            <small>PROJEKT</small>
                            <h5 style="min-height: 20px;">
                                {{ project.project_shortname }}
                            </h5>
                        </div>
                        <div>
                            <router-link :to="'/project/' + id + '/edit'" class="btn btn-outline-primary me-2 f-cap">
                               Edytuj projekt
                            </router-link>
                        </div>
                    </div>

                </div>
            </div>

            <ProjectNavComponent :project_id="id" value="task"></ProjectNavComponent>

            <div class="col-12 col-md-12 filter-bar kanban-container mb-5">
                <task_index_columns :taskprefix="'/task/' + id" :project_id="id" @change="getPosts" :posts="allItems"
                    v-if="!isLoading">
                </task_index_columns>
            </div>


        </div>
    </div>
</template>
<script>
import axios from '../../api/axios';
import TaskIndexColumns from './components/TaskIndexColumns.vue';
import api from '../../api/api';
import ProjectNavComponent from './components/ProjectNavComponent.vue';

export default {
    name: 'task',
    template: '#task-template',
    components: {
        'task_index_columns': TaskIndexColumns,
        ProjectNavComponent,
    },
    data: function () {
        return {
            BASE_URL: api.getBaseUrl(),
            slownik_user: this.$store.state.slownik_user,
            slownik_project_settlements: this.$store.state.slownik_project_settlements,
            slownik_project_contract: this.$store.state.slownik_project_contract,
            slownik_user_position: this.$store.state.slownik_user_position,

            g_slownik_contract: [],
            g_slownik_project_settlements: [],
            isLoading: true,
            id: 0,
            allItems: [],
            client: {},
            project: {
                client_id: 0,
                project_name: '',
                project_created_at: '',
                project_status: '',
                project_settlements: '',
                project_contract: '',
                boards: [],
            },
            search: {
                page: 1,
                limit: 100,
                pages: 1,
                task_status: '',
                task_name: '',
                task_priority: '',
                task_post_id: '',
                task_module: 'project',
            },
            collapse1_show: false,
            collapse2_show: false,
            collapse3_show: false,
            collapse4_show: false,
            collapse5_show: false,
            collapse6_show: false,
            collapse7_show: false,
            collapse8_show: false,
            files: [],
            links: [],
        }
    },
    computed: {},
    methods: {
        lang(x) { return x },
        postionFromSlownik(user_position) {
            this.slownik_user_position.forEach(a => {
                user_position = a.value == user_position ? a.name : user_position;
            })
            return user_position;
        },
        handleAxiosError: function (error) {
            if (error.response.data.messages) {
                for (const property in error.response.data.messages) {
                    this.validation[property] = error.response.data.messages[property];
                }
            } else console.log(error);
        },
        updateProject: function () {
            axios.put(this.BASE_URL + 'api/v1/project/' + this.project.project_id, this.project).then(() => {
                //MAGICO_PANEL.alert('success', 'projekt zaktualizowany');
            }).catch(this.handleAxiosError);
        },
        patchProject: function () {
            var vm = this;
            vm.project.project_settlements = '';
            vm.slownik_project_settlements.forEach(i => {
                if (vm.g_slownik_project_settlements[i.value]) vm.project.project_settlements = vm.project.project_settlements + i.value + '|';
            });
            vm.project.project_contract = '';
            vm.slownik_project_contract.forEach(i => {
                if (vm.g_slownik_contract[i.value]) vm.project.project_contract = vm.project.project_contract + i.value + '|';
            });
            console.log('g_slownik_contract', this.g_slownik_contract, this.project.project_contract);
            console.log('g_slownik_project_settlements', this.g_slownik_project_settlements, vm.project.project_settlements);
            this.updateProject();
        },
        changeCheckboxSettlement(item) {
            console.log(item)
            this.patchProject();
        },
        changeCheckboxContract() {
            this.patchProject();
        },
        convertSettlement(itemsString) {
            var vm = this;
            var ar = itemsString.split('|');
            this.slownik_project_settlements.forEach(i => {
                vm.g_slownik_project_settlements[i.value] = false
                ar.forEach(y => {
                    if (y == i.value) vm.g_slownik_project_settlements[i.value] = true;
                })
            })
        },
        convertContract(itemsString) {
            var vm = this;
            var ar = itemsString.split('|');
            this.slownik_project_contract.forEach(i => {
                vm.g_slownik_contract[i.value] = false
                ar.forEach(y => {
                    if (y == i.value) vm.g_slownik_contract[i.value] = true;
                })
            })
        },
        generateTooltip: function (item, variant) {
            var text = '';
            item.notifications.forEach(i => {
                if (i.task_notification_status == 'done') {
                    text = '<s>' + i.task_notification_end_at + '</s>' + '<br />' + text;
                } else {
                    text = '' + i.task_notification_end_at + '' + '<br />' + text;
                }
            })
            var a = {
                title: text,
                placement: 'bottom',
                variant: variant,
                'html': true
            }
            return a;
        },
        getClient: function () {
            let vm = this;
            axios.get(this.BASE_URL + 'api/v1/client/' + this.project.client_id).then(response => {
                vm.client = response.data;
            });
        },
        getProject: function () {
            let vm = this;
            vm.isLoading = true;
            axios.get(this.BASE_URL + 'api/v1/project/' + this.id).then(response => {
                //stworzenie pustych walidacji
                vm.convertSettlement(response.data.project_settlements);
                vm.convertContract(response.data.project_contract);
                console.log(response.data.representative);
                response.data.workstages.forEach(item => {
                    item.workstage_status = (item.workstage_status == 1) ? true : false;
                })
                response.data.settlementstages.forEach(item => {
                    item.settlementstage_status = (item.settlementstage_status == 1) ? true : false;
                })

                vm.project = response.data;
                vm.getClient();

                vm._links = response.data._links;
                vm.loadFiles();

            });
        },
        getUser: function (id) {
            var name = '';
            this.slownik_user.forEach(item => {
                if (item.user_id == id) {
                    name = item.name;
                    return
                }
            })
            return name;
        },
        clickPage(page) {
            this.search.page = page;
            this.getPosts();
        },
        onDelete() {
            this.getPosts();
        },
        clickShow: function (item) {
            this.$router.push('/task/' + item.project_id);
        },
        clickEdit: function (item) {
            this.$router.push('/task/' + this.id + '/edit/' + item.task_id);
        },
        getPosts: function () {

            let vm = this;
            vm.isLoading = true;
            axios.get(this.BASE_URL + 'api/v1/task', {
                params: this.search
            }).then(response => {
                vm.allItems = response.data.data;
                vm.search.pages = response.data.pages;
                vm.isLoading = false;
                // vm.loadFiles();
            });
        },
        submitSearchForm: function () {
            this.search.page = 1;
            this.getPosts();
        },
        resetSearchForm: function () {
            for (const property in this.search) {
                this.search[property] = '';
            }
            this.getPosts();
        },
        loadFiles: function () {
            console.log(this._links.files);
            axios.get(this._links.files.href).then(response => {
                this.files = response.data.data;
                console.log(this.files);
            })
        },
    },
    created() {
        // this.search.task_group = this.$route.params.slownik ? this.$route.params.slownik : '';
        this.id = this.$route.params.id;
        this.search.task_post_id = this.id;
        this.getProject();
        this.getPosts();
    }
}
</script>
<style>
.kanban-board-header .kanban-title-board {
    font-size: 1.125rem;
    max-width: 13rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
}

.kanban-title-board::first-letter {
    text-transform: capitalize;
}
</style>