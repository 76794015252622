<template id="task_index_columns-template">
    <div class="row h-100 ">
        <div class="col-12 col-md-3  h-100">
            <header class="kanban-board-header mb-4 d-flex justify-content-between align-items-center">
                <div class="kanban-title-board "> {{ $t('task.header.todo') }}</div>
                <div class="dropdown"><i class=" bx bx-dots-vertical-rounded cursor-pointer fs-4"
                        id="board-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="board-dropdown" style="">
                        <TaskAddModal :project_id="project_id" @change="$emit('change')"></TaskAddModal>
                       
                    </div>
                </div>



            </header>

            <div class="text-center kanban-empty" v-if="items.todo.length == 0">
                Upuść tutaj
            </div>
            <draggable class="list-group h-100" :list="items.todo" group="people" @change="moveTaskTodo">
                <task_index_item_box @change="$emit('change')" v-for="item in items.todo" :post="item" variant="primary"
                    :editurl="(taskprefix ? taskprefix : '') + '/show/' + item.task_id" :key="item.task_id">
                </task_index_item_box>
            </draggable>
        </div>
        <div class="col-12 col-md-3 h-100">
            <header class="kanban-board-header mb-4">
                <div class="kanban-title-board"> {{ $t('task.header.progress') }}</div>
            </header>
            <div class="text-center kanban-empty" v-if="items.progress.length == 0">
                Upuść tutaj
            </div>
            <draggable class="list-group h-100" :list="items.progress" group="people" @change="moveTaskProgress">
                <task_index_item_box @change="$emit('change')" v-for="item in items.progress" :post="item" variant="warning"
                    :editurl="(taskprefix ? taskprefix : '') + '/show/' + item.task_id" :key="item.task_id">
                </task_index_item_box>
            </draggable>
        </div>

        <div class="col-12 col-md-3  h-100">
            <header class="kanban-board-header mb-4">
                <div class="kanban-title-board"> {{ $t('task.header.check') }}</div>
            </header>
            <div class="text-center kanban-empty" v-if="items.check.length == 0">
                Upuść tutaj
            </div>
            <draggable class="list-group h-100" :list="items.check" group="people" @change="moveTaskCheck">
                <task_index_item_box @change="$emit('change')" v-for="item in items.check" :post="item" variant="check"
                    :editurl="(taskprefix ? taskprefix : '') + '/show/' + item.task_id" :key="item.task_id">
                </task_index_item_box>
            </draggable>
        </div>

        <div class="col-12 col-md-3  h-100">
            <header class="kanban-board-header mb-4">
                <div class="kanban-title-board"> {{ $t('task.header.done') }}</div>
            </header>
            <div class="text-center kanban-empty" v-if="items.done.length == 0">
                Upuść tutaj
            </div>
            <draggable class="list-group h-100" :list="items.done" group="people" @change="moveTaskDone">
                <task_index_item_box @change="$emit('change')" v-for="item in items.done" :post="item" variant="success"
                    :editurl="(taskprefix ? taskprefix : '') + '/show/' + item.task_id" :key="item.task_id">
                </task_index_item_box>
            </draggable>
        </div>
    </div>
</template>
<script>
import axios from '../../../api/axios'
import draggable from 'vuedraggable'
import TaskIndexItemBox from './TaskIndexItemBox.vue'
import api from '../../../api/api'
import TaskAddModal from './TaskAddModal.vue'
export default {
    name: 'TaskIndexColumns',
    components: {
    "draggable": draggable,
    "task_index_item_box": TaskIndexItemBox,
    TaskAddModal
},
    props: ['posts', 'editurl', 'taskprefix','project_id'],
    data: function () {
        return {
            BASE_URL: api.getBaseUrl(),
            slownik_user: this.$store.state.slownik_user,
            slownik_project_settlements: this.$store.state.slownik_project_settlements,
            slownik_project_contract: this.$store.state.slownik_project_contract,
            g_slownik_contract: [],
            g_slownik_project_settlements: [],
            isLoading: true,
            id: 0,
            items: {
                todo: [],
                progress: [],
                done: [],
                check: [],
            },
            client: {},
            project: {
                client_id: 0,
                project_name: '',
                project_created_at: '',
                project_status: '',
                project_settlements: '',
                project_contract: '',
            },
            search: {
                page: 1,
                limit: 100,
                pages: 1,
                task_status: '',
                task_name: '',
                task_priority: '',
                task_post_id: '',
                task_module: 'project',
            },
            collapse1_show: true,
            collapse2_show: true,
            collapse3_show: true,
            collapse4_show: true,
            collapse5_show: true,
        }
    },
    computed: {},
    methods: {
        lang(x) { return x },
        moveTaskTodo(evt) {
            if (evt.added && evt.added.element && evt.added.element.task_id) {
                evt.added.element.task_status = 'todo'
                this.updateTask(evt.added.element)
            }
        },
        moveTaskProgress(evt) {
            if (evt.added && evt.added.element && evt.added.element.task_id) {
                evt.added.element.task_status = 'progress'
                this.updateTask(evt.added.element)
            }
        },
        moveTaskDone(evt) {
            if (evt.added && evt.added.element && evt.added.element.task_id) {
                evt.added.element.task_status = 'done'
                this.updateTask(evt.added.element)
            }
        },
        moveTaskCheck(evt) {
            if (evt.added && evt.added.element && evt.added.element.task_id) {
                evt.added.element.task_status = 'check'
                this.updateTask(evt.added.element)
            }
        },
        updateTask(item) {
            axios.put(this.BASE_URL + 'api/v1/task/' + item.task_id, item).then(() => { }).catch(this.handleAxiosError);
        },
    },
    created() {
        console.log('posty', this.posts);
        this.posts.forEach(item => {
            if (item.task_status == 'done') {
                this.items.done.push(item);
            } else if (item.task_status == 'progress') {
                this.items.progress.push(item);
            } else if (item.task_status == 'check') {
                this.items.check.push(item);
            }
            else {
                this.items.todo.push(item);
            }
        })
    }
}
</script>
<style>
.kanban-empty {
    padding: 1rem;
    border: 1px dashed rgb(158, 158, 158);
    margin-bottom: 1rem;
}
</style>